<template>
  <b-card
    no-body
    
  >
    <b-card-header v-if="!isLoading">
      <b-card-title>{{$t($route.name)}}</b-card-title>
        <b-button
            class=""
            variant='success'
            @click='createItem'
            v-if='resolvePermission("show.create")'
          >
          <feather-icon
            icon="PlusIcon"
            size="15"
          >

          </feather-icon>
          {{$t('add new')}}
        </b-button>
    </b-card-header>
    <b-row class="mx-2">
      <b-col>
        <b-form-group
          :label='$t("Search")'
          label-for="basicInput"
        >
          <b-form-input
            id="basicInput"
            v-model='searchText'
          />
        </b-form-group>
      </b-col>
      <!-- <b-col>
        <b-form-group
          label="Basic Input"
          label-for="selectInput"
        >
          <b-form-select
            id='selectInput'
            v-model="selected"
            :options="options"
          />
          <v-select
            v-model="selected"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="title"
            :options="options"
          />
        </b-form-group>
      </b-col> -->
      <b-col cols='1' class="align-self-center">
        <b-button 
          variant='primary'
          class="btn my-auto"
          :disabled ='loading'
          @click="filterItems"
        >
          <feather-icon
            icon='SearchIcon'
            size='16'
          ></feather-icon>
        </b-button>
      </b-col>
    </b-row>

    <b-table
      responsive
      :items="items"
      :fields="fields"
      class="mb-0"
    >
      <!-- <template #cell(Phone)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template> -->
      <template #cell(status)="data">
        <b-form-checkbox 
          @change="(e) => changeStatus(data, e)"
          v-if='resolvePermission("show.edit")'
          switch
          v-model="data.item.status"
        >
        </b-form-checkbox>
      </template>
      <template #cell(cover)="data">
        <div 
          class="position-relative px-0 text-center"
          @click="openDropzoneSelect(data, 'cover')"  
        >
          <img 
            :src='data.item.cover_image_url ? data.item.cover_image_url : placeHolder' 
            width="40" 
            height="40" 
          />
          <span 
            v-if="editHover[data.index] && editHover[data.index]['cover']"
            class="edit-ready"  
          > 
          </span>
        </div>
        
      </template>
      <template #cell(tv)="data">
        <div 
          class="position-relative text-center" style="padding: 0 5px"
          @click="openDropzoneSelect(data, 'tv')"    
        >
          <img 
            :src='data.item.tv_img && data.item.tv_img.url ? data.item.tv_img.url : placeHolder' 
            width="40" 
            height="40"   
          />

          <span 
            v-if="editHover[data.index] && editHover[data.index]['tv']"
            class="edit-ready"  
          > 
          </span>
        </div>
      </template>
      <template #cell(player)="data">
        <div 
          class="position-relative px-0 text-center" 
          @click="openDropzoneSelect(data, 'player')"   
        >
          <img 
            :src='data.item.player_image_url ? data.item.player_image_url : placeHolder' 
            width="40" 
            height="40" 
            class="position-relative"
            
          />

          <span 
            v-if="editHover[data.index] && editHover[data.index]['player']"
            class="edit-ready"  
          > 
          </span>
        

        </div>
      </template>
      <template #cell(titles)="data">
        <b-button
          variant="outline-dark "
          @click="editTitle(data)"
          class="table-text-edit-btn position-relative"
        >
          <span 
            v-if="editHover[data.index] && editHover[data.index]['title']"
            class="edit-ready"  
          > 
          </span>
          {{ data.value }}
        </b-button>
        <b-button
          variant="outline-dark"
          @click="editDescription(data)"
          class="desc-btn position-relative"
        >
          <span 
            v-if="editHover[data.index] && editHover[data.index]['description']"
            class="edit-ready"  
          > 
            <!-- <feather-icon icon="EditIcon" size="12" class="m-1 text-primary"></feather-icon> -->
          </span>
          <div class="table-desc-edit-btn  " v-if="data.item.descriptions">
            
            {{ data.item.descriptions[$i18n.locale] }}
          </div>
        </b-button>
      </template>
      <template #cell(announcers)="data">
        <div class="d-flex align-items-center flex-wrap">
          <span
            v-for="(ann, annInd) in data.value"
            :key="ann.id"
          >
            <b-avatar 
              :id="`ann-${ann.id * data.index}`"
              :src="ann.image_url || ann.cover_image_url || placeHolder"
              class="table-avatar"
              button
              @click="confirmRemovePresenter(data, ann.id)"
            >
            </b-avatar>
            <b-popover 
              :title="ann.title" 
              :target="`ann-${ann.id * data.index}`" 
              triggers="hover"
              variant="dark"
              placement="auto"
            ></b-popover>
          </span>
  

          <b-avatar 
            class="pointer position-relative table-avatar-plus"
            button
          >
            <select 
              :id="`select-${data.item.id}`"
              class="pointer table-select-dropdown"
              @input="(e) => openPresentersDropDown(data, e)"
            >
              <option
                :value="null"
              > 
                {{ "Choose Presenter" }}
              </option>
              <option
                v-for="presenter in presenters"
                :value="presenter.id"
              > 
                {{ presenter.title }}
              </option>
            </select>
            <Feather-Icon icon="PlusIcon" />
          </b-avatar>
        </div>

      </template>
      <template #cell(social_links)="data">
        <div class="d-flex">
          <span
            v-for="social in socialLinks"
            style="margin: 0px 2px"
            class="pointer position-relative"
            @click="editSocials(social, data)"
          >
            <span 
              v-if="editHover[data.index] && editHover[data.index][social.key]"
              class="edit-ready img"  
            > 
              <feather-icon icon="EditIcon" size="12" class="m-1 text-primary"></feather-icon>
            </span>
            <img 
              :class="{
                'no-link': !data.item.social_links[social.key],
              }"
              height="25"
              width="25"
              :src="social.icon"
            />
          </span>
        </div>
      </template>
      <template #cell(times)="data">
        <feather-icon
          icon="CalendarIcon"
          size="25"
          class="pointer"
          @click="openShowTimes(data)"
        />
      </template>
      <!-- Optional default data cell scoped slot -->
      <template #cell()="data">
        {{ data.value }}
      </template>
      <template #cell(actions)="row">
          <!-- <b-button 
            size="sm" 
            @click="showItem(row)"
            variant="flat-dark"  
            class="btn-icon"
          >
            <feather-icon
              icon="EyeIcon"
              size="15"
            />
          </b-button> -->
          <!-- <b-button 
            size="sm" 
            @click="editItem(row)"
            variant="flat-dark"  
            class="btn-icon"
            v-if='resolvePermission("show.edit")'
          >
            <feather-icon
              icon="Edit2Icon"
              size="15"
            />
          </b-button> -->
          <b-button 
            size="sm" 
            @click="deleteItem(row)"
            variant="flat-danger"  
            class="btn-icon"
            v-if='resolvePermission("show.delete")'
          >
            <feather-icon
              icon="Trash2Icon"
              size="15"
            />
          </b-button>
        </template>
    </b-table>
    <b-card-footer>
      <!-- <b-pagination
        v-model="currentPage"
        hide-goto-end-buttons
        :total-rows="rows"
        :per-page="perPage"
        @change="filterItems"
      /> -->
    </b-card-footer>
  </b-card>
</template>

<script>
import {  BTable, BButton, BRow, BCol , 
  BCard, BCardTitle, BCardHeader, BCardFooter, 
  BFormGroup, BFormInput, BFormSelect,
  BPagination, BAvatar, 
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BPopover
} from 'bootstrap-vue';
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { mapActions, mapGetters } from 'vuex';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTable,
    BButton,
    BCardCode,
    BCol,
    BRow,
    BCard, BCardTitle, BCardHeader, BCardFooter,
    BFormGroup, BFormInput, BFormSelect,
    BPagination, BAvatar, BDropdown, BDropdownItem,
    BFormCheckbox,
    BPopover
  },
  data() {
    return {
      fields: [
        {
          key: 'id',
          label: this.$t('#')
        },
        {
          key: 'cover',
          label: this.$t('Mobile')
        },
        {
          key: 'tv',
          label: this.$t('TV')
        },
        {
          key: 'player',
          label: this.$t('player')
        },
        {
          key: 'titles',
          label: this.$t('name'),
          formatter: (value, key, item) => value ? value[this.$i18n.locale] : ''
        },
        // {
        //   key: 'descriptions',
        //   label: this.$t('description'),
        //   formatter: (value, key, item) => value ? value[this.$i18n.locale] : ''
        // },
        {
          key: 'announcers',
          label: this.$t('Presenters')
        },
        {
          key: 'times',
          label: this.$t('Times')
        },
        {
          key: 'social_links',
          label: this.$t('Socials')
        },
        {
          key: 'status',
          label: this.$t('status')
        },
        // {
        //   key: 'created_at',
        //   label: 'date',
        //   formatter: (value, key, item) => this.formateDate(item),
        // },
        {
          key: 'actions',
          label: this.$t('actions')
        },
      ],
      currentPage: 1,
      perPage: 10,
      rows: 0,
      searchText: '',
      isLoading: true,
      editHover: []
    }
  },
  props: ['openModal', 'closeModal', 'confirmDelete', 'setEditObj', 'confirmDeleteFullItem', 'alertMissingImages'],
  created() {
    this.getShows({all_locale: '1', limitedBy: 100})
      .then(res => {
        this.getPresenters({
          limitedBy: -1
        }).then(pres => {
          this.isLoading = false;
        });
        this.rows = res.data.results.total;
        this.populateEditHover();
      }).catch(err => console.error(err))
  },
  methods: {
    ...mapActions(['getShows', 'deleteShow', 'getShow', 'getPresenters', 'createShow']),
    filterItems() {
      setTimeout(() => {
        this.getShows({
          page: this.currentPage,
          day: this.day,
          today: this.today,
          keywords: this.searchText,
          all_locale: '1',
          limitedBy: 100
        }).then(res => {
          this.rows = res.data.total;  
          this.populateEditHover();
        }).catch(err => {
  
        })
      }, 100)
    },
    populateEditHover() {
      for(let i = 0; i <= this.rows; i++) {
        this.editHover.push({
          facebook: false,
          instagram: false,
          twitter: false,
          whatsapp: false,
          youtube: false,
          title: false,
          description: false,
          cover: false,
          tv: false,
          player: false,
        });
      }
    },
    showItem(row) {
      this.openModal(row, 'Show')
      // console.log(row)
    },
    createItem() {
      this.$router.push({
        name: 'CreateItem',
        params: {
          item: 'show'
        }
      })
    },
    editItem(row) {
      // this.openModal(row, 'Edit');
      this.setEditObj(row.item);
      this.$router.push({
        name: 'EditItem',
        params: {
          item: 'show',
          item_id: row.item.id
        }
      })
    },

    deleteItem(row) {
      this.confirmDelete(this.deleteShow, row)
    },
    editShowText(formData) {
      this.$store.commit('setModalLoading', true);
      this.createShow(formData)
      .then(res => {
        this.$store.commit('setModalLoading', true);
        this.closeModal();
        this.filterItems();
      })
    },
    editTitle(item) { 
      let currentTitle = this.editHover[item.index]['title'];
      if(!currentTitle) {
        this.editHover[item.index]['title'] = true;
        setTimeout(() => {
          this.editHover[item.index]['title'] = false;
        }, 5000);
      } else {
        //* open modal form for editing text ar and en only
        this.openModal({}, 'TextEditForm', 'Edit Title');
        this.$store.commit('setModalOpts', {
          formFields: [ 
            { name: 'Title AR', type: 'text', model: 'titleAr', rules: '', cols: '12' },
            { name: 'Title EN', type: 'text', model: 'titleEn', rules: '', cols: '12' }
          ],
          submitFn: this.editShowText,
          item: item.item,
          key: 'title'
        })

      }
    },
    editDescription(item) { 
      let currentTitle = this.editHover[item.index]['description'];
      if(!currentTitle) {
        this.editHover[item.index]['description'] = true;
        setTimeout(() => {
          this.editHover[item.index]['description'] = false;
        }, 5000);
      } else {
        //* open modal form for editing text ar and en only
        this.openModal({}, 'TextEditForm', 'Edit Description');
        this.$store.commit('setModalOpts', {
          formFields: [ 
            { name: 'Description AR', type: 'textarea', model: 'descriptionAr', rules: '', cols: '12' },
            { name: 'Description EN', type: 'textarea', model: 'descriptionEn', rules: '', cols: '12' }
          ],
          submitFn: this.editShowText,
          item: item.item,
          key: 'description'
        })
      }
    },
    changeStatus(row, value) {
      let announcers_id = row.item.announcers ? row.item.announcers.map(ann => ann.id) : [];
      this.createShow({
        ...row.item,
        ...row.item.social_links,
        announcers_id,
        status: value
      })

    },
    openPresentersDropDown(row, event) {
      console.log("openPresentersDropDown => row:", row)
      let announcers_ids = row.item.announcers ? row.item.announcers.map(ann => ann.id) : [];
      let newID = Number(event.target.value);
      if(announcers_ids.includes(newID)) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Presenter already exist on this Program'),
            icon: '',
            variant: 'danger',
          },
        });

      } else {
        this.addNewPresenter(row, newID);
      }
    },
    removeDeletedImages(show) {
      let coverImageExists = show.cover_image_id && show.cover_image_url != null
      let playerImageExists = show.player_image_id && show.player_image_url != null
      let tvImageExists = show.tv_image_id && show.tv_image_url != null;
      let missingImg = false;

      if(!coverImageExists) {
        show.cover_image_id = 0;
        missingImg = true;
      }
      if(!playerImageExists) {
        show.player_image_id = 0;
        missingImg = true;
      }
      if(!tvImageExists) {
        show.tv_image_id = 0;
        missingImg = true;
      }
      return {show, missingImg};
    },
    addNewPresenter(row, newID) {
      console.log("addNewPresenter => row:", row)
      let announcers_ids = row.item.announcers ? row.item.announcers.map(ann => ann.id) : [];
      let {show, missingImg} = this.removeDeletedImages(row.item);
      this.createShow({
        ...show,
        ...row.item.social_links,
        announcers_id: [...announcers_ids, Number(newID)]
      }).then(res => {
        this.filterItems();
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Presenter added successfully'),
            icon: '',
            variant: 'success',
          },
        });
      }).catch(err => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.response.data.results.message,
            icon: '',
            variant: 'danger',
          },
        });
      })
    },
    removePresenterFlow(item) {
      let removeID = item.removeID;
      let announcers = item.announcers ? item.announcers.map(ann => ann.id) : [];
      let index = announcers.findIndex(id => id == removeID);
      let {show, missingImg} = this.removeDeletedImages(item);
      if(index != -1) {
        announcers.splice(index, 1);
        return new Promise((resolve, reject) => {
          this.createShow({
            ...show,
            ...item.social_links,
            announcers_id: announcers
          }).then(res => {
            resolve(res);
            this.filterItems();
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('Presenter removed successfully'),
                icon: '',
                variant: 'success',
              },
            });
          }).catch(err => {
            reject(err)
          })
          
        })

      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Presenter was not found'),
            variant: 'danger',
          },
        });
      }
    },
    confirmRemovePresenter(row, removeID) {
      this.confirmDeleteFullItem(this.removePresenterFlow, {...row.item, removeID})
    },
    openShowTimes(row) {
      this.openModal(row, 'DaysRepeaterForm', 'Update Times');
    },
    editSocials(social, row) {
      let currentSocial = this.editHover[row.index][social.key];
      if(!currentSocial){
        this.editHover[row.index][social.key] = true;
        setTimeout(() => {
          this.editHover[row.index][social.key] = false;
        }, 5000)
      } else {
        this.openModal({}, 'TextEditForm', 'Edit Socials');
        this.$store.commit('setModalOpts', {
          formFields: [ 
            { name: social.name, type: 'text', model: social.key, rules: '', cols: '12' },
          ],
          submitFn: this.editShowText,
          item: row.item,
          key: social.key
        })
      }
    },
    openDropzoneSelect(row, imgType) {
      let currentImg = this.editHover[row.index][imgType];
      if(!currentImg){
        this.editHover[row.index][imgType] = true;
        setTimeout(() => {
          this.editHover[row.index][imgType] = false;
        }, 5000)
      } else {
        let currentFile = row.item[`${imgType}_img`];
        // if(currentFile != null) {
          row.item.imgType = imgType;
          this.openModal({
            item: {
              id: row.item.id,
              titles: row.item.titles,
              descriptions: row.item.descriptions,
              ...row.item.social_links,

              selectable: false,
              selectFile: this.resolveSelectFunction(imgType),
              name: imgType,
              selectable: { id: this.resolveSelectedImg(row, imgType) },
            }
          }, 'SelectImage', 'Edit Show Images');
        // }
        
      }
    },
    resolveSelectedImg(row, imgType) {
      if(imgType == 'cover') {
        return row.item.cover_image_id;
      }
      if(imgType == 'player') {
        return row.item.player_image_id;
      }
      if(imgType == 'tv') {
        return row.item.tv_image_id;
      }
    },
    resolveSelectFunction(imgType) {
      if(imgType == 'cover') {
        return this.selectCover;
      }
      if(imgType == 'player') {
        return this.selectPlayer;
      }
      if(imgType == 'tv') {
        return this.selectTV;
      }
    },
    selectCover(file, formObj) {
      if(formObj.id) {
        delete formObj.player_image_id;
        delete formObj.tv_image_id;
        this.createShow({
          ...formObj,
          cover_image_id: file.id
        }).then(res => {
          this.filterItems();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Cover Image Updated successfully'),
              icon: '',
              variant: 'success',
            },
          });
        })
      } else {
        
      }
    },
    selectPlayer(file, formObj) {
      if(formObj.id) {
        delete formObj.cover_image_id;
        delete formObj.tv_image_id;
        this.createShow({
          ...formObj,
          player_image_id: file.id
        }).then(res => {
          this.filterItems();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Player Image Updated successfully'),
              icon: '',
              variant: 'success',
            },
          });
        })
      }
    },
    selectTV(file, formObj) {
      if(formObj.id) {
        delete formObj.cover_image_id;
        delete formObj.player_image_id;
        this.createShow({
          ...formObj,
          tv_image_id: file.id
        }).then(res => {
          this.filterItems();
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('TV Image Updated successfully'),
              icon: '',
              variant: 'success',
            },
          });
        })
      }
    },
  },

  computed: {
    ...mapGetters({
      items: 'shows',
      presenters: 'presenters'
    }),
    socialLinks() {
      return [
        { name: 'facebook', icon: require('@/assets/socialicons/facebook.png'), key: 'facebook' },
        { name: 'whatsapp', icon: require('@/assets/socialicons/whatsapp.png'), key: 'whatsapp' },
        { name: 'youtube', icon: require('@/assets/socialicons/youtube.png'), key: 'youtube' },
        { name: 'instagram', icon: require('@/assets/socialicons/instagram.png'), key: 'instagram' },
        { name: 'X', icon: require('@/assets/socialicons/X.png'), key: 'twitter' },
      ]
    },
  }
}
</script>